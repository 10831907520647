<!--
--------------------------------------------------------------------------------
<copyright file="BendingStatus.vue" company="Bystronic Laser AG">
 Copyright (C) Bystronic Laser AG 2021-2024
</copyright>
--------------------------------------------------------------------------------
-->

<template>
  <section>
    <details>
      <summary>{{ $t('widget_info_text.bend_current_state') }}</summary>
      <p class="text">{{ $t('widget_info_text.txt_color_display') }}</p>
      <ul class="list">
        <li class="list-item">
          <span class="demi-bold"> {{ $t('widget_info_text.overview_view') }}:</span>
          {{ $t('widget_info_text.overview_view_txt') }}
        </li>
        <li class="list-item">
          <span class="demi-bold"> {{ $t('widget_info_text.machine_view') }}:</span>
          {{ $t('widget_info_text.machine_view_txt') }}
        </li>
        <li class="list-item">
          <span class="demi-bold"> {{ $t('widget_info_text.color_code') }}:</span>
          <ul class="list">
            <li class="list-item2">
              {{ $t('widget_info_text.green') }}: {{ $t('widget_info_text.green_txt') }}<br /><img
                src="images/info/cutStatusGreen.jpg"
              />
            </li>
            <li class="list-item2">
              {{ $t('widget_info_text.orange') }}: {{ $t('widget_info_text.lOrange_txt')
              }}<br /><img src="images/info/cutStatusOrange.jpg" />
            </li>
            <li class="list-item2">
              {{ $t('widget_info_text.brown') }}: {{ $t('widget_info_text.brown_txt') }}<br /><img
                src="images/info/cutStatusBrown.jpg"
              />
            </li>
            <li class="list-item2">
              {{ $t('widget_info_text.red') }}: {{ $t('widget_info_text.red_txt') }}<br /><img
                src="images/info/cutStatusRed.jpg"
              />
            </li>
          </ul>
        </li>
      </ul>
      <p class="info list-item info-bkg">{{ $t('widget_info_text.info') }}:</p>
      <p class="text info-bkg">{{ $t('widget_info_text.bend_status_info_txt') }}</p>
    </details>
    <details>
      <summary>{{ $t('widget_info_text.machine_health') }}</summary>
      <ul class="list">
        <li class="list-item">
          <span> {{ $t('widget_info_text.machine_health_txt') }}</span>
        </li>
      </ul>
    </details>
    <details>
      <summary>{{ $t('widget_info_text.parts_produced') }}</summary>
      <ul class="list">
        <li class="list-item">{{ $t('widget_info_text.parts_produced_txt') }}</li>
      </ul>
    </details>
    <details>
      <summary>{{ $t('widget_info_text.kg_produced') }}</summary>
      <ul class="list">
        <li class="list-item">{{ $t('widget_info_text.kg_produced_txt') }}</li>
      </ul>
    </details>
    <details>
      <summary>{{ $t('widget_info_text.operating_hours') }}</summary>
      <ul class="list">
        <li class="list-item">
          <span> {{ $t('widget_info_text.operating_hours_txt') }} </span>
        </li>
      </ul>
    </details>
  </section>
</template>

<script language="ts">
import { Component, Vue } from 'vue-property-decorator';
@Component
export default class BendingStatusInfo extends Vue {}
</script>
